const updateChatHelper = (newContent, setChats) => {
  setChats((prevChats) => {
    const lastChatIndex = prevChats.length - 1;
    const currentStep = prevChats[lastChatIndex]?.data?.step;

    if (currentStep && prevChats[lastChatIndex]?.data[currentStep.key]) {
      return prevChats.map((chat, index) => {
        if (index === lastChatIndex) {
          const updatedChat = {
            ...chat,
            data: {
              ...chat.data,
              data: {
                ...chat.data.data,
                [currentStep.key]: {
                  ...chat.data.data[currentStep.key],
                  content: newContent,
                },
              },
              [currentStep.key]: {
                ...chat.data[currentStep.key],
                content: newContent,
              },
            },
            isAIChecking: false,
          };
          return updatedChat;
        }
        return chat;
      });
    }

    return prevChats;
  });
};

export default updateChatHelper;
