const scrollToBottom = (containerRef) => {
  if (containerRef?.current) {
    containerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
    containerRef?.current?.scrollTo(0, 1e10);
  }
};

export default scrollToBottom;
