const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "1",
      // width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "2",
      // width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "3",
      // width: 400,
    },
    {
      title: "Debug Level",
      dataIndex: "debugLevel:",
      key: "4",
      // width: 500,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "5",
    },
  ];

  export default columns;