const updateChatField = (setChats,field,fieldValue) => {
  setChats((prev) => {
    const updatedChats = [...prev];
    if (updatedChats.length > 0) {
      updatedChats[updatedChats.length - 1][field] = fieldValue;
    }
    return updatedChats;
  });
};

export default updateChatField;