import { AIEvaluation } from "../../../Redux/Features/Chat/AIEvaluationSlice";
import { AIRequery } from "../../../Redux/Features/Chat/AIRequerySlice";
import updateChatField from "../Chat/updateChatField";
import updateChatHelper from "../Chat/updateChatHelper";
const aiCheckerAndHumanChecker = async (
  chats,
  isResume,
  conversationId,
  setChats,
  aiEvalResponse,
  aiRequeryResponse,
  dispatch,
  problemId
) => {
  const lastChat = chats[chats.length - 1]?.data;
  if (isResume) return;

  const currentStep = lastChat?.step;
  if (!currentStep) return;

  const {
    task,
    input,
    format,
    aiChecker,
    aiCheckerInstructions,
    aiCheckerThreshold = 10,
    humanCheckerStyle = "never",
    humanCheckerThreshold = 8,
  } = currentStep;
  const language = lastChat.language;
  const currentResponse =
    lastChat[currentStep.key]?.content || "previous response has no content";
  const { _id, stepId } = lastChat.library;
  let previousUserMessage = `${task}\n---\n${input}`;
  if (format) {
    previousUserMessage += `\n---\nFormat using ${format}`;
  }

  let aiChecked = false;
  let aiCheckedValue = 8;

  if (aiChecker) {
    dispatch(
      AIEvaluation({
        problemId,
        previousUserMessage,
        aiInstruction: aiCheckerInstructions || "",
        aichecker: aiChecker,
        currentResponse,
        language,
        conversationId,
        stepId,
        libraryId: _id,
        debugLevel: 0,
        action: "AI Eval",
        onSuccess: () => {
          aiCheckedValue = aiEvalResponse.data?.data[0];
          aiChecked = true;
          if (aiCheckedValue < aiCheckerThreshold) {
            dispatch(
              AIRequery({
                problemId,
                aichecker: aiChecker,
                previousUserMessage,
                currentResponse,
                currentStepKey: currentStep.key,
                recommendations: aiEvalResponse.data?.data[1],
                language,
                conversationId,
                stepId,
                libraryId: _id,
                debugLevel: 0,
                action: "AI Requery",
                onSuccess: () => {
                  updateChatHelper(aiRequeryResponse, setChats);
                },
                onError: () => {
                  updateChatField(setChats, "isAIChecking", false);
                },
              })
            );
          } else {
            updateChatField(setChats, "isAIChecking", false);
          }
        },
        onError: () => {
          updateChatField(setChats, "isAIChecking", false);
        },
      })
    );
  }

  if (
    humanCheckerStyle === "always" ||
    (humanCheckerStyle === "threshold" &&
      aiChecked &&
      aiCheckedValue < humanCheckerThreshold)
  ) {
    updateChatField(setChats, "humanChecker", true);
  }
};

export default aiCheckerAndHumanChecker;
