import axios from "axios";
import { BASE_URL } from "../../Config";
import updateChatHelper from "../problemConversation/Chat/updateChatHelper";
import plantUMLImageGenerator from "../problemConversation/PlantUMLDiagram/plantUMLCodeToImageGenerator";
import { getCookie } from "../helper";

const processContent = async (
  problemId,
  content,
  chats,
  setPlantUMLPResent,
  setIsWritting,
  setImage,
  conversationId,
  language,
  isResume,
  setChats
) => {
  const userId = getCookie('userId');
  const codeRegex = /```plantuml\s*@startuml([\s\S]*?)@enduml\s*```/g;
  let match;
  let updatedContent = content;

  const { _id, stepId } = chats[chats.length - 1].data?.library;

  let codeError = "";
  while ((match = codeRegex.exec(content)) !== null) {
    setPlantUMLPResent(true);
    let code = match[0];
    let correctedCode = code;
    if (!isResume) {
      try {
        const response = await axios.post(
          `${BASE_URL}/api/user/plantUML/check`,
          {
            plantumlCode: code,
            userId,
          }
        );
        codeError = response?.data?.data;
      } catch (error) {
        setPlantUMLPResent(false);
        break;
      }

      if (codeError?.includes("PlantUML syntax error found")) {
        try {
          let response = await axios.post(
            `${BASE_URL}/api/user/chats/aicodeCorrector/${problemId}`,
            {
              code,
              codeError,
              language,
              conversationId,
              libraryId: _id,
              stepId,
              action: "Code Error Correction",
              debugLevel: 0,
            }
          );
          correctedCode = response?.data?.data;
        } catch (error) {
          setPlantUMLPResent(false);
          break;
        }
      }
      updatedContent = updatedContent.replace(code, correctedCode);
      updateChatHelper(updatedContent, setChats);
    }

    const imageData = await plantUMLImageGenerator(correctedCode);
    let imageUrl = "";
    if (imageData) {
      imageUrl = URL.createObjectURL(new Blob([imageData]));
      setImage(imageUrl);
    }
  }
  setIsWritting(false);
};

export default processContent;
