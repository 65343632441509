const { deleteProblem } = require("../../Redux/Features/Problem/deleteProblemSlice");
const { deleteProblemData } = require("../../Redux/Features/Problem/getAllProblemsSlice");
const { toastSuccess } = require("../toast");

const handleDeleteProblem = (id,dispatch) => {
    dispatch(
      deleteProblem({
        id,
        onSuccess: (data) => {
          dispatch(deleteProblemData(id));
          toastSuccess(data);
        },
      })
    );
  };

  export default handleDeleteProblem;