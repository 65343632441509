import { Modal, Input, Button } from "antd";
import ReactMarkdown from "react-markdown";
import handleDownload from "../../utils/problemConversation/Document/compileToDocument";
import styles from "./Editor.module.scss";
import { useDispatch, useSelector } from "react-redux";
const EditorModal = ({
  isEditorModalVisible,
  handleUpdateResponse,
  handleCancelEditorModal,
  editedResponse,
  setEditedResponse,
  isCompiling,
  compiledChats,  
  setCompiledChats
}) => {
  const dispatch = useDispatch();
  const {isLoading:loading} = useSelector((state)=>state.compileChatsToDocument);
  let content = isCompiling ? compiledChats : editedResponse;
  const codeRegex = /```([\s\S]*?)```/g;
  let match;
  let lastIndex = 0;
  let processedMarkdown = "";

  while ((match = codeRegex.exec(content)) !== null) {
    const codeBlock = match[0];

    processedMarkdown += content
      .slice(lastIndex, match.index)
      .replace(/\n/g, "\n&nbsp;");
    processedMarkdown += codeBlock;
    lastIndex = match.index + codeBlock.length;
  }

  processedMarkdown += content.slice(lastIndex).replace(/\n/g, "\n&nbsp;");

  return (
    <Modal
      title={isCompiling ? "Preview Compiled Chats" : "Edit Chat Response"}
      open={isEditorModalVisible}
      onOk={handleUpdateResponse}
      onCancel={handleCancelEditorModal}
      okText="Save"
      cancelText="Cancel"
      width={1200}
      footer={[
        isCompiling && (
          <Button
            key="download"
            type="primary"
            disabled={loading}
            loading={loading}
            onClick={()=>handleDownload(compiledChats, dispatch)}
            value="download"
          >
            Download
          </Button>
        ),
        <Button key="cancel" onClick={handleCancelEditorModal}>
          Cancel
        </Button>,
        !isCompiling && (
          <Button key="save" type="primary" onClick={handleUpdateResponse}>
            Save
          </Button>
        ),
      ]}
    >
      <div className={styles.boxesContainer}>
        <Input.TextArea
          rows={4}
          value={content}
          id={styles.editor}
          onChange={
            isCompiling
              ? (event) => setCompiledChats(event.target.value)
              : (event) => setEditedResponse(event.target.value)
          }
          style={{ height: "80vh" }}
        />
        <div id={styles.preview}>
          <ReactMarkdown className={styles.linebreak}>
            {processedMarkdown}
          </ReactMarkdown>
        </div>
      </div>
    </Modal>
  );
};

export default EditorModal;
