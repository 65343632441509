import React from "react";
import { Col, Typography, Button } from "antd";
import DebugViewerModal from "./Modals/debugViewerModal";
import styles from "../Pages/ProblemConversation/ProblemConversation.module.scss";
import ChatItem from "./chatItem";
import handleResume from "../utils/problemConversation/Actions/handleResume";

const ChatList = ({
  chats,
  conversationId,
  acceptResponseLoading,
  isMdOrAbove,
  isSMOrBellow,
  userId,
  openDebugModal,
  cancelDebugModal,
  isTyping,
  isResume,
  isChatCompleted,
  isWritting,
  setIsWritting,
  setChats,
  setOpenDebugModal,
  editedResponse,
  setEditedResponse,
  setIsEditorModalVisible,
  setIsChat,
  setIsTyping,
  dispatch,
  navigate,
  setMessage,
  problemData,
  problemId,
  message,
  setIsResume,
}) => {
  return (
    <div className={styles.chats_container}>
      {chats?.map((item, i) => (
        <ChatItem
          key={i}
          item={item}
          i={i}
          chats={chats}
          conversationId={conversationId}
          userId={userId}
          acceptResponseLoading={acceptResponseLoading}
          isMdOrAbove={isMdOrAbove}
          isSMOrBellow={isSMOrBellow}
          isWritting={isWritting}
          setIsWritting={setIsWritting}
          isResume={isResume}
          setChats={setChats}
          setOpenDebugModal={setOpenDebugModal}
          editedResponse={editedResponse}
          setEditedResponse={setEditedResponse}
          setIsEditorModalVisible={setIsEditorModalVisible}
          setIsChat={setIsChat}
          setIsTyping={setIsTyping}
          dispatch={dispatch}
          navigate={navigate}
          setMessage={setMessage}
          problemData={problemData}
          problemId={problemId}
          message={message}
        />
      ))}

      {isResume && !isChatCompleted && (
        <Col>
          <Button
            type="primary"
            onClick={() =>
              handleResume(
                setChats,
                chats,
                setMessage,
                setIsChat,
                dispatch,
                setIsTyping,
                navigate,
                problemData,
                problemId,
                message,
                conversationId,
                setIsResume
              )
            }
          >
            Resume
          </Button>
        </Col>
      )}

      {!isResume && isTyping && (
        <div className={styles.typing_container}>
          <Typography.Paragraph>Typing...</Typography.Paragraph>
        </div>
      )}

      <DebugViewerModal open={openDebugModal} onCancel={cancelDebugModal} />
    </div>
  );
};

export default ChatList;
