import addChatUtility from "./addChat";
import addUserChat from "./addUserChats";
const handleMessage = (
  data,
  setChats,
  chats,
  setMessage,
  setIsChat,
  dispatch,
  setIsTyping,
  navigate,
  problemData,
  problemId,
  message,
  conversationId,
  humanCheckerAnswer = ""
) => {
  setIsChat(false);
  setIsTyping(true);
  if (chats?.length === 0) {
    addUserChat(chats,problemData?.description || data?.description, setChats);
  }
  if (message) {
    addUserChat(chats,message, setChats);
  }
  addChatUtility(
    data,
    dispatch,
    setIsTyping,
    navigate,
    problemData,
    problemId,
    message,
    conversationId,
    humanCheckerAnswer
  );

  setMessage("");
};

export default handleMessage;
