const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "1",
      // width: "80px",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "2",
      // width: "80px",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "3",
      // width: "200px",
    },
    {
      title: "Promptlet",
      dataIndex: "promptlet",
      key: "4",
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "5",
    },
  ];

  export default columns;