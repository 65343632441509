const compileChats = (chats, setCompiledChats, setIsCompiling,setIsEditorModalVisible) => {
    let compiledContent = "";
    for (let chat of chats) {
      const dataKeys = Object.keys(chat.data || {});

      // Loop through each key in chat data
      for (let key of dataKeys) {
        if (chat.data[key]?.content) {
          compiledContent += chat.data[key].content + "\n";
        }
      }
    }

    // Set the compiled content to the edited response
    setCompiledChats(compiledContent.trim());
    setIsCompiling(true);
    setIsEditorModalVisible(true);
  
};

export default compileChats;
