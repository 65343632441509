import { getAllLogsOfStepInSpecificConversation } from "../../../Redux/Features/Log/getAllStepLogsOfConversationSlice";

const handleDebug = async (
  stepId,
  conversationId,
  setOpenDebugModal,
  dispatch
) => {
  setOpenDebugModal(true);
  dispatch(
    getAllLogsOfStepInSpecificConversation({
      stepId,
      conversationId
    })
  );
};

export default handleDebug;
