import React, { useEffect } from "react";
import BasicLayout from "../../Layout/BasicLayout";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import styles from "./Users.module.scss";
import { deleteUserData, getAllUsers } from "../../Redux/Features/User/getAllUsersSlice";
import DataSource from "./DataSource";
import columns from "./Columns";
import { deleteUser } from "../../Redux/Features/User/deleteUserSlice";
import { toastSuccess } from "../../utils/toast";
const DisplayUsers = () => {
  const dispatch = useDispatch();

  const { data: users, isLoading: getAllUsersLoading } = useSelector(
    (state) => state?.getAllUsers
  );
  const { user: currentLoggedInUser } = useSelector((state) => state.getUser);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const handleDeleteUser = (id) => {
    dispatch(
      deleteUser({
        id,
        onSuccess: (data) => {
          dispatch(deleteUserData(id))
          toastSuccess(data);
        },
      })
    );
  };

  return (
    <BasicLayout>
      <div className={styles.users_container}>
        <div className={styles.table_container}>
          <Table
            loading={getAllUsersLoading}
            dataSource={DataSource(
              users,
              currentLoggedInUser,
              handleDeleteUser,
            )}
            columns={columns}
          />
        </div>
      </div>
    </BasicLayout>
  );
};

export default DisplayUsers;
