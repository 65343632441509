
import { compileChatsToDocument } from "../../../Redux/Features/Document/compileDocumentSlice";
import { toastError } from "../../toast";

const handleDownload = async (compileChats, dispatch) => {
  dispatch(
    compileChatsToDocument({
      compileChats,
      onSuccess: (compileDocument) => {
        if (compileDocument) {
          const url = window.URL.createObjectURL(new Blob([compileDocument]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${Date.now()}_compiled.docx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // Clean up after the download
        }
        else{
          toastError("Compilation failed please try again");
        }
      },
      onError: (message) => {
        toastError(message);
      },
    })
  );
};

export default handleDownload;
