import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosErrorHandler, getCookie } from "../../../utils/helper";
import setAuthToken from "../../../utils/axios/setAuthToken";
import axios from "axios";
import { BASE_URL } from "../../../Config";

export const AIRequery = createAsyncThunk(
  "AI Requery",
  async (
    {
      problemId,
      aichecker: aiChecker,
      previousUserMessage,
      currentResponse,
      currentStepKey,
      recommendations,
      language,
      conversationId,
      stepId,
      libraryId,
      debugLevel,
      action,
      onSuccess,
      onError
    },
    { rejectWithValue }
  ) => {
    try {
      const token = getCookie("token");
      setAuthToken(token);
      const response = await axios.post(
        `${BASE_URL}/api/user/chats/aiRequery/${problemId}`,
        {
          aichecker: aiChecker,
          previousUserMessage,
          currentResponse,
          currentStepKey,
          recommendations,
          language,
          conversationId,
          stepId,
          libraryId,
          debugLevel,
          action,
          onSuccess,
        }
      );
      onSuccess && onSuccess();
      return response?.data;
    } catch (error) {
      onError&&onError();
      axiosErrorHandler(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = {
  isLoading: false,
  isSuccess: false,
  message: "",
  data: "",
};
const AIRequerySlice = createSlice({
  name: "AI Requery",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AIRequery.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(AIRequery.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action?.payload?.message;
      state.data = action?.payload?.data;
    });
    builder.addCase(AIRequery.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.message = action?.payload?.message;
    });
  },
});

export default AIRequerySlice?.reducer;
