import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Config";
import setAuthToken from "../../../utils/axios/setAuthToken";
import { axiosErrorHandler, getCookie } from "../../../utils/helper";


export const addConversation = createAsyncThunk(
  "Add Log",
  async ({ conversationId, userId, problemId }, { rejectWithValue }) => {
    try {
      const token = getCookie("token");
      setAuthToken(token);
      const response = await axios.post(`${BASE_URL}/api/user/conversations/`, {
        conversationId,
        problemId,
        userId,
      });
      return response?.data;
    } catch (error) {
      axiosErrorHandler(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = {
  isLoading: false,
  isSuccess: false,
  message: "",
};
const addConversationSlice = createSlice({
  name: "Add Log",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addConversation.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(addConversation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action?.payload?.data;
    });
    builder.addCase(addConversation.rejected, (state,action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.message = action?.payload?.data;
    });
  },
});

export default addConversationSlice?.reducer;
