
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosErrorHandler, getCookie } from "../../../utils/helper";
import setAuthToken from "../../../utils/axios/setAuthToken";
import axios from "axios";
import { BASE_URL } from "../../../Config";

const initialState = {
    isLoading: false,
    isError: false,
    errors: [],
    isSuccess: false,
    message: "",
  
};

export const deletePromptlet = createAsyncThunk("chat/delete-promptlet",
    async ({ id, onSuccess,onError }, { rejectWithValue }) => {
        try {
            let token = getCookie('token');
            setAuthToken(token);
            const response = await axios.delete(`${BASE_URL}/api/user/library/${id}`);
            onSuccess && onSuccess(response?.data?.message);
            return response?.data;

        } catch (error) {
            axiosErrorHandler(error);
            onError&&onError(error?.response?.data?.message)
            return rejectWithValue(error?.response?.data);
        }
    }
);

const deletePromptletSlice = createSlice({
    name: "delete promptlet slice",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(deletePromptlet.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.errors = [];
        });

        builder.addCase(deletePromptlet.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = action.payload.success;
            state.isError = !action.payload.success;
            state.message = action.payload.message;
        });

        builder.addCase(deletePromptlet.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
        });
    }
});

export default deletePromptletSlice.reducer;
