import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Config";
import setAuthToken from "../../../utils/axios/setAuthToken";
import { getCookie } from "../../../utils/helper";

export const mergeChatsToDocument = createAsyncThunk(
  "Add Log",
  async ({ formData, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const token = getCookie("token");
      setAuthToken(token);
      const response = await axios.post(
        `${BASE_URL}/api/user/document/merge`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        }
      );

      onSuccess && onSuccess(response?.data); // Pass Blob data to onSuccess
      return response?.data;
    } catch (error) {
      const errorMessage = error?.response?.data || error?.message;
      onError && onError(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const initialState = {
  isLoading: false,
  isSuccess: false,
  documentBlob: null, // Store the Blob directly
  errorMessage: "", // Renamed for clarity
};

const mergeChatsToDocumentSlice = createSlice({
  name: "Add Log",
  initialState: initialState,
  reducers: {
    cancelMergeDocument: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(mergeChatsToDocument.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(mergeChatsToDocument.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(mergeChatsToDocument.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = action?.payload; // Store error message
    });
  },
});
export const { cancelMergeDocument } = mergeChatsToDocumentSlice.actions;
export default mergeChatsToDocumentSlice.reducer;
