import { cancelMergeDocument } from "../../../Redux/Features/Document/mergeDocumentSlice";

const handleCancelMergeDocument = (
  setDocumentFile,
  setIsMergeDocumentModalVisible,
  dispatch
) => {
  dispatch(cancelMergeDocument());
  setDocumentFile(null);
  setIsMergeDocumentModalVisible(false);
};

export default handleCancelMergeDocument;
