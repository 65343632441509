import axios from "axios";
import { BASE_URL } from "../../../Config";
import { getCookie } from "../../helper";

const plantUMLImageGenerator = async (correctedCode) => {
  const userId = getCookie("userId");
  try {
    const generateImageResponse = await axios.post(
      `${BASE_URL}/api/user/plantUML/generateImage`,
      {
        plantumlCode: correctedCode,
        userId,
      },
      {
        responseType: "blob",
      }
    );
    return generateImageResponse.data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export default plantUMLImageGenerator;
