import { Tooltip } from "antd";
import { formatDateToStandard } from "../../../utils/helper";
import styles from "../Threads.module.scss";

const DataSource = (problemsData) => {
  return problemsData?.map((item, i) => {
    return {
      key: i,
      date: (
        <p className={styles.paragraph1}>
          {formatDateToStandard(item?.created_at)}
        </p>
      ),
      title: <p className={styles.paragraph2}>{item?.problem?.title}</p>,
      description: (
        <p className={styles.paragraph3}>
          <Tooltip
            title={item?.problem?.description}
          >{`${item?.problem?.description?.slice(0, 50)}${
            item?.problem?.description?.length > 78 ? "..." : ""
          }`}</Tooltip>
        </p>
      ),
      promptlet: (
        <p className={styles.paragraph2}>{item?.problem?.library?.name}</p>
      ),
      author: <p className={styles.paragraph2}>{item?.user?.email}</p>,
    };
  });
};

export default DataSource;
