import { getCookie } from "../helper";

const handleStepsData = (id, promptlets) => {
  const userId = getCookie("userId");

  if (promptlets?.length > 0) {
    if (id) {
      // Find the specific promptlet by ID
      const step = promptlets.find(
        (item) => item._id.toString() === id.toString()
      );
      const stepKeys = step?.steps?.map((key) => key.key);
      return `${step?.name} (${stepKeys?.join(", ")})`;
    } else {
      // Separate "my promptlets" and "community promptlets"
      const myPromptlets = promptlets.filter(
        (item) => item.user?._id.toString() === userId
      );

      const communityPromptlets = promptlets.filter(
        (item) => item.user?._id.toString() !== userId
      );

      // Map the promptlets to the required format
      const myPromptletsData = myPromptlets.map((item) => {
        const keyData = item.steps?.map((key) => key.key);
        return {
          label: `${item.name} (${keyData.join(", ")})`,
          value: item._id,
          author: item?.user?.name,
          visibility: item?.visibility,
        };
      });

      const communityPromptletsData = communityPromptlets.map((item) => {
        const keyData = item.steps?.map((key) => key.key);
        return {
          label: `${item.name} (${keyData.join(", ")})`,
          value: item._id,
          author: item?.user?.name,
          visibility: item?.visibility,
        };
      });

      // Return both sets of data, you can format it as per your requirements
      return {
        myPromptlets: myPromptletsData,
        communityPromptlets: communityPromptletsData,
      };
    }
  }

  return [];
};

export default handleStepsData;
