import { Button, Space } from "antd";
import styles from "./Users.module.scss"; 
import { formatDateToStandard } from "../../utils/helper"; 
import { useSelector } from "react-redux";

const DataSource = (users, currentLoggedInUser, handleDeleteUser) => {
  const {isLoading} = useSelector((state)=>state.deleteUser);
  return users?.map((user, i) => ({
    key: i,
    date: (
      <p className={styles.paragraph1}>
        {formatDateToStandard(user?.created_at)}
      </p>
    ),
    name: <p className={styles.paragraph2}>{user?.name}</p>,
    email: <p className={styles.paragraph3}>{user?.email}</p>,
    debugLevel: <p className={styles.paragraph3}>{user?.debugLevel}</p>,
    action: (
      <Space>
        {user?.email !== currentLoggedInUser?.email && (
          <Button
            style={{ color: "red" }}
            disabled={isLoading}
            loading={isLoading}
            onClick={() => handleDeleteUser(user?._id)}
          >
            Delete
          </Button>
        )}
      </Space>
    ),
  }));
};

export default DataSource;
