import axios from "axios";
import { BASE_URL } from "../../../Config";

const getThread = async (problemId,setChats,setIsLoadingChats) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/threads/${problemId}`
      );
      const thread = response.data;

      if (thread?.data?.[0]?.response) {
        const newChats = thread.data[0].response.map((responseStep) => {
          return {
            data: responseStep,
            isMoreButtons: false,
            isResume:true,
          };
        });

        setChats((prevChats) => [...prevChats, ...newChats]);
      } else {
        console.log("No response data found in thread");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoadingChats(false);
    }
  };

  export default getThread;