import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Typography,
  Row,
  Col,
  Collapse,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import styles from "../../Pages/Home/Home.module.scss";
import saveAsNewPromptlet from "../../utils/promptlet/saveAsNewPromptlet";
import TextArea from "antd/es/input/TextArea";
const { Panel } = Collapse;
const { Option } = Select;

const AddPromptletModal = ({
  addPromptletLoading,
  setPromptlet,
  promptlet,
  setClientErrors,
  dispatch,
}) => {
  const [form] = Form.useForm();
  const [steps, setSteps] = useState(promptlet?.steps || []);

  const handleAddStep = () => {
    setSteps([...steps, {}]);
  };

  const handleRemoveStep = (index) => {
    setSteps(steps.filter((_, i) => i !== index));
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        saveAsNewPromptlet(
          { ...promptlet, ...values },
          setClientErrors,
          dispatch,
          setPromptlet
        );
      })
      .catch((error) => {
        console.error("Validation Failed:", error);
      });
  };

  return (
    <Modal
      centered
      open={promptlet?.open}
      onCancel={() => {
        setPromptlet({
          open: false,
          title: "",
          steps: [],
          visibility: "sharedUnlocked",
        });
      }}
      width={900}
      footer={null}
    >
      <div className={styles.add_problem_container}>
        <Form form={form} initialValues={{ steps }} layout="vertical">
          <Typography.Title level={3}>Add Promptlet</Typography.Title>
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item
                label="Promptlet Title"
                name="title"
                rules={[{ required: true, message: "Please enter a title" }]}
              >
                <Input
                  placeholder="Promptlet Title"
                  size="large"
                  onChange={(e) =>
                    setPromptlet({ ...promptlet, title: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Visibility">
                <Select
                  value={promptlet?.visibility || "sharedUnlocked"}
                  onChange={(value) => {
                    setPromptlet((prevPromptlet) => ({
                      ...prevPromptlet,
                      visibility: value,
                    }));
                  }}
                >
                  <Option value="private">Private</Option>
                  <Option value="sharedUnlocked">Shared (Unlocked)</Option>
                  <Option value="sharedLocked">Shared (Locked)</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Typography.Title level={4}>Steps</Typography.Title>
          {steps.map((step, index) => (
            <div key={index} className={styles.step}>
              <Row gutter={16}>
                <Typography.Text style={{ textAlign: "left" }}>
                  Step {index + 1}{" "}
                </Typography.Text>
                <Col span={24}>
                  <Button
                    type="text"
                    danger
                    icon={<MinusCircleOutlined />}
                    onClick={() => handleRemoveStep(index)}
                    style={{ float: "right" }}
                  >
                    Remove Step
                  </Button>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Key"
                    name={["steps", index, "key"]}
                    rules={[{ required: true, message: "Key is required" }]}
                  >
                    <Input placeholder="Key" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Title"
                    name={["steps", index, "title"]}
                    rules={[{ required: true, message: "Title is required" }]}
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Order"
                    name={["steps", index, "order"]}
                    rules={[{ required: true, message: "Order is required" }]}
                  >
                    <Input type="number" placeholder="Order" min={1} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Persona"
                    name={["steps", index, "persona"]}
                    rules={[{ required: true, message: "Persona is required" }]}
                  >
                    <Input placeholder="Persona" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Task"
                    name={["steps", index, "task"]}
                    rules={[{ required: true, message: "Task is required" }]}
                  >
                    <TextArea placeholder="Task" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Input"
                    name={["steps", index, "input"]}
                    rules={[{ required: true, message: "Input is required" }]}
                  >
                    <TextArea placeholder="Input" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Format" name={["steps", index, "format"]}>
                    <Input placeholder="Format" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Active" name={["steps", index, "active"]}>
                    <Select placeholder="Active" defaultValue={true}>
                      <Option value={true}>True</Option>
                      <Option value={false}>False</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="AI Checker"
                    name={["steps", index, "aiChecker"]}
                  >
                    <Input placeholder="AI Checker" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="AI Checker Instructions"
                    name={["steps", index, "aiCheckerInstructions"]}
                  >
                    <Input placeholder="AI Checker Instructions" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="AI Checker Threshold"
                    name={["steps", index, "aiCheckerThreshold"]}
                  >
                    <Input type="number" placeholder="AI Checker Threshold" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Human Checker Style"
                    name={["steps", index, "humanCheckerStyle"]}
                  >
                    <Input placeholder="Human Checker Style" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Human Checker Prompt"
                    name={["steps", index, "humanCheckerPrompt"]}
                  >
                    <Input placeholder="Human Checker Prompt" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Human Checker Threshold"
                    name={["steps", index, "humanCheckerThreshold"]}
                  >
                    <Input
                      type="number"
                      placeholder="Human Checker Threshold"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
          <Button
            type="dashed"
            onClick={handleAddStep}
            style={{ width: "100%", marginBottom: "20px" }}
            icon={<PlusOutlined />}
          >
            Add Step
          </Button>
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                onClick={handleSave}
                loading={addPromptletLoading}
              >
                {addPromptletLoading ? "Saving..." : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddPromptletModal;
