import { mergeChatsToDocument } from "../../../Redux/Features/Document/mergeDocumentSlice";
import { generatePlantUMLDiagram } from "../../../Redux/Features/PlantUMLDiagram/generatePlantUMLDiagramSlice";
import { getCookie } from "../../helper";
import { toastError } from "../../toast";

const handleMergeDocument = async (documentFile, chats, dispatch) => {
  const userId = getCookie("userId");
  if (!documentFile) {
    toastError("Template Document file is required.");
    return;
  }

  const data = chats[chats.length - 1]?.data?.data || {};
  const formData = new FormData();
  const steps = Object.keys(data);

  // Process PlantUML diagrams asynchronously
  for (const step of steps) {
    const stepContent = data[step]?.content || "";
    let match;
    const codeRegex = /```plantuml\s*@startuml([\s\S]*?)@enduml\s*```/g;

    while ((match = codeRegex.exec(stepContent)) !== null) {
      let codeContent = match[0];
      await new Promise((resolve) => {
        dispatch(
          generatePlantUMLDiagram({
            plantumlCode: codeContent,
            userId,
            onSuccess: (imageData) => {
              if (imageData) {
                formData.append(`${step}`.toUpperCase(), imageData);
              }
              resolve();
            },
            onError: (message) => {
              toastError(message);
              resolve();
            },
          })
        );
      });
    }

    formData.append(`step_${step}`.toUpperCase(), stepContent);
  }

  // Append the document file
  formData.append("doc", documentFile);

  // Dispatch the merge action
  dispatch(
    mergeChatsToDocument({
      formData,
      onSuccess: (documentData) => {
        if (documentData) {
          const url = window.URL.createObjectURL(new Blob([documentData]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${Date.now()}_merged.docx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
      onError: (message) => {
        toastError(message);
      },
    })
  );
};

export default handleMergeDocument;
