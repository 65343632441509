import React, { useEffect, useState } from "react";
import BasicLayout from "../../Layout/BasicLayout";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import styles from "./Promptlets.module.scss";
import { getLibrary } from "../../Redux/Features/Promptlet/getLibrarySlice";
import columns from "./Columns";
import DataSource from "./dataSource";
import AddPromptletModal from "../../components/Promptlets/addPromptletModal";
import ViewPromptletModal from "../../components/Promptlets/viewPromptletModal";
import EditPromptletModal from "../../components/Promptlets/EditPromptletModal";

const Promptlet = () => {
  const dispatch = useDispatch();
  const [promptlet, setPromptlet] = useState({
    open: false,
    title: "",
    steps: [],
    visibility:"sharedUnlocked"
  });
  const [promptletToEditModal, setPromptletToEditModal] = useState({
    open: false,
    id: "",
    title: "",
    steps: [],
    visibility:""
  });
  const [viewPromptletModal, setViewPromptletModal] = useState({
    open: false,
    id: "",
    title: "",
    steps: [],
  });

  const { data: promptlets, isLoading: getAllPrompletsLoading } = useSelector(
    (state) => state?.getLibrary
  );

  const { isLoading: addPromptletLoading } = useSelector(
    (state) => state?.addPromptlet
  );
  const { isLoading: updatePromptletLoading } = useSelector(
    (state) => state?.updatePromptlet
  );

  let [clientErrors, setClientErrors] = useState({});

  useEffect(() => {
    dispatch(getLibrary());
  }, []);

  return (
    <BasicLayout>
      <div className={styles.promptlets_container}>
        <div className={styles.button_container}>
          <Button
            type="primary"
            onClick={() =>
              setPromptlet({
                open: true,
                id: "",
                title: "",
                steps: [],
              })
            }
          >
            Add Promptlet
          </Button>
        </div>
        <div className={styles.table_container}>
          <Table
            loading={getAllPrompletsLoading}
            dataSource={DataSource(
              promptlets,
              setPromptletToEditModal,
              dispatch,
              setViewPromptletModal,
            )}
            columns={columns}
          />
        </div>
      </div>
      <AddPromptletModal
        addPromptletLoading={addPromptletLoading}
        setPromptlet={setPromptlet}
        promptlet={promptlet}
        setClientErrors={setClientErrors}
        dispatch={dispatch}
      />

      <EditPromptletModal
        promptletToEditModal={promptletToEditModal}
        setPromptletToEditModal={setPromptletToEditModal}
        updatePromptletLoading={updatePromptletLoading}
        setClientErrors={setClientErrors}
        dispatch={dispatch}
        clientErrors={clientErrors}
      />
      <ViewPromptletModal
        viewPromptletModal={viewPromptletModal}
        setViewPromptletModal={setViewPromptletModal}
      />
    </BasicLayout>
  );
};

export default Promptlet;
