import { Table } from "antd";

const expandedRowRender = (selectedRows,problemsData) => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Content",
        dataIndex: "content",
        key: "content",
      },
    ];

    const responses = problemsData?.map((item) => {
      return item?.response;
    });

    for (let key in responses) {
      const item = responses[key];
      if (key?.toString() == selectedRows?.key?.toString()) {
        return <Table columns={columns} dataSource={item} pagination={false} />;
      }
    }
  };

  export default expandedRowRender;