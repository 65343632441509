import React from "react";
import { Modal, Button } from "antd";
import { JsonViewer } from "@textea/json-viewer";
import styles from "../../Pages/ProblemConversation/ProblemConversation.module.scss";
import { useSelector } from "react-redux";

const DebugViewerModal = ({ open, onCancel }) => {
  const { data: currentStepLogs, isLoading: isLogLoading } = useSelector(
    (state) => state.getAllLogsOfStepInSpecificConversation
  );

  return (
    <Modal
      title="Debug"
      open={open}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
      width={800}
    >
      <div styles={{ backgroundColor: "#f5f5f5" }}>
        {isLogLoading ? (
          <div className={styles.shimmerWrapper}>
            <div
              className={`${styles.shimmer} ${styles.shimmerLine} ${styles.short}`}
            ></div>
            <div
              className={`${styles.shimmer} ${styles.shimmerLine} ${styles.medium}`}
            ></div>
            <div
              className={`${styles.shimmer} ${styles.shimmerLine} ${styles.long}`}
            ></div>
            <div
              className={`${styles.shimmer} ${styles.shimmerLine} ${styles.short}`}
            ></div>
            <div
              className={`${styles.shimmer} ${styles.shimmerLine} ${styles.medium}`}
            ></div>
          </div>
        ) : (
          <>
            {currentStepLogs && Object.keys(currentStepLogs).length > 0  ? (
              <JsonViewer
                value={currentStepLogs}
                theme="monokai"
                collapsed={false}
                displayDataTypes={false}
                displaySize={false}
                enableClipboard={true}
                style={{ whiteSpace: "pre-wrap" }}
              />
            ) : (
              <div className={styles.noLogsFound}>No Logs found</div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default DebugViewerModal;
