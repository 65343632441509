import React from "react";
import { Form, Input, Modal, Select, Typography } from "antd";
import styles from "../../Pages/Home/Home.module.scss";
import handleUpdateProblem from "../../utils/home/handleUpdateProblem";
import handleStepsData from "../../utils/home/handleStepsData";
import { LockOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const EditProblemModal = ({
  editProblemModal,
  setEditProblemModal,
  updateProblemLoading,
  setClientErrors,
  dispatch,
  clientErrors,
  promptlets,
}) => {
  const handleCancel = () => {
    setEditProblemModal({
      open: false,
      id: "",
      title: "",
      description: "",
      library: "",
      language: "",
    });
    setClientErrors({});
  };

  const handleOk = () => {
    handleUpdateProblem(
      editProblemModal,
      setClientErrors,
      dispatch,
      setEditProblemModal
    );
  };

  const stepsData = handleStepsData(null, promptlets);

  return (
    <Modal
      centered
      open={editProblemModal?.open}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Update"
      confirmLoading={updateProblemLoading}
      width={900}
    >
      <div className={styles.edit_problem_container}>
        <Typography.Title level={3}>Edit Problem</Typography.Title>
        <Form layout="vertical">
          <Form.Item
            label="Problem Title"
            validateStatus={clientErrors?.title ? "error" : ""}
            help={clientErrors?.title}
          >
            <Input
              placeholder="Problem Title"
              size="large"
              value={editProblemModal?.title}
              onChange={(e) =>
                setEditProblemModal({
                  ...editProblemModal,
                  title: e.target.value,
                })
              }
            />
          </Form.Item>

          <Form.Item
            label="Problem Description"
            validateStatus={clientErrors?.description ? "error" : ""}
            help={clientErrors?.description}
          >
            <TextArea
              placeholder="Problem Description"
              size="large"
              rows={5}
              value={editProblemModal?.description}
              onChange={(e) =>
                setEditProblemModal({
                  ...editProblemModal,
                  description: e.target.value,
                })
              }
            />
          </Form.Item>

          <Form.Item
            label="Library"
            validateStatus={clientErrors?.library ? "error" : ""}
            help={clientErrors?.library}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder="Choose Library"
              value={editProblemModal?.library || undefined}
              onChange={(value) =>
                setEditProblemModal({ ...editProblemModal, library: value })
              }
            >
              <OptGroup label="My Promptlets">
                {stepsData?.myPromptlets?.map((item) => (
                  <Option key={item.value} value={item.value}>
                    <span>{item.label}</span>

                    <span
                      style={{
                        color: "#8c8c8c",
                        fontStyle: "italic",
                        marginLeft: 8,
                      }}
                    >
                      {item.author}
                    </span>
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label="Shared Promptlets">
                {stepsData?.communityPromptlets?.map((item) => (
                  <Option key={item.value} value={item.value}>
                    <span>{item.label}</span>
                    <span
                      style={{
                        color: "#8c8c8c",
                        fontStyle: "italic",
                        marginLeft: 8,
                      }}
                    >
                      {item.author}
                    </span>
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>

          <Form.Item label="Language">
            <Input
              placeholder="Language"
              size="large"
              value={editProblemModal?.language}
              onChange={(e) =>
                setEditProblemModal({
                  ...editProblemModal,
                  language: e.target.value,
                })
              }
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditProblemModal;
