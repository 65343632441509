import { formatDateToStandard, getCookie } from "../../utils/helper";
import { Button, Space } from "antd";
import styles from "./Promptlets.module.scss";
import handleDeletePromptlet from "../../utils/promptlet/handleDeletePromplet";
import { useSelector } from "react-redux";

const DataSource = (
  promptlets,
  setPromptletToEditModal,
  dispatch,
  setViewPrompletModal
) => {
  const authUser = JSON.parse(getCookie("authUser") || "{}");
  const { isLoading } = useSelector((state) => state.deleteUser);
  return promptlets?.map((item, i) => {
    const isOwner = item?.user?._id === authUser._id;
    const isAdmin = authUser.userType === "admin";

    return {
      key: i,
      date: (
        <p className={styles.paragraph1}>
          {formatDateToStandard(item?.created_at)}
        </p>
      ),
      title: <p className={styles.paragraph2}>{item?.name}</p>,
      name: <p className={styles.paragraph2}>{item?.user?.name}</p>,
      visibility: <p className={styles.paragraph2}>{item?.visibility}</p>,
      action: (
        <Space>
          <Button
            style={{ color: "red" }}
            disabled={(!isOwner && !isAdmin) || isLoading}
            loading={isLoading}
            onClick={() => handleDeletePromptlet(item?._id, dispatch)}
          >
            Delete
          </Button>

          {/* Edit Promptlet */}
          <Button
            type="primary"
            size="small"
            disabled={!isOwner && !isAdmin}
            onClick={() => {
              setPromptletToEditModal({
                open: true,
                id: item?._id,
                title: item?.name,
                steps: item?.steps,
                visibility: item?.visibility,
              });
            }}
          >
            Edit
          </Button>

          {/* View Promptlet */}
          <Button
            type="primary"
            size="small"
            disabled={
              item?.visibility === "sharedLocked" && !isAdmin && !isOwner
            }
            onClick={() => {
              setViewPrompletModal({
                open: true,
                id: item?._id,
                title: item?.name,
                steps: item?.steps,
              });
            }}
          >
            View
          </Button>
        </Space>
      ),
    };
  });
};

export default DataSource;
