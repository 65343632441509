import validator from "validator";
import { addProblem } from "../../Redux/Features/Problem/addProblemSlice";
import { getAllProblems } from "../../Redux/Features/Problem/getAllProblemsSlice";

const saveAsNewProblem = async (problem,setClientErrors,dispatch,setProblem,setAddProblemModal,setEditProblemModal) => {
    let isErr = false;
    let errors = {};

    if (validator.isEmpty(problem?.title)) {
      isErr = true;
      errors.title = "Title is required";
    }
    if (validator.isEmpty(problem?.description)) {
      isErr = true;
      errors.description = "Description is required";
    }

    if (problem?.steps?.length === 0) {
      isErr = true;
      errors.steps = "Steps is required";
    }

    if (isErr) {
      isErr = false;
      setClientErrors(errors);
    } else {
      isErr = false;
      setClientErrors({});
      dispatch(
        addProblem({
          data: problem,
          onSuccess: () => {
            setProblem({
              title: "",
              description: "",
              library: "",
              language: "",
            });
            dispatch(getAllProblems());
            setAddProblemModal(false);
            setEditProblemModal({
              open: false,
              id: "",
              title: "",
              description: "",
              library: "",
              language: "",
            });
          },
        })
      );
    }
  };


  export default saveAsNewProblem;