import validator from "validator";
import { updateProblem } from "../../Redux/Features/Problem/updateProblemSlice";
import { restartProblem } from "../../Redux/Features/Problem/restartProblemSlice";
import { getAllProblems } from "../../Redux/Features/Problem/getAllProblemsSlice";
import { toastSuccess } from "../toast";

const handleUpdateProblem = async (
  editProblemModal,
  setClientErrors,
  dispatch,
  setEditProblemModal
) => {
  let isErr = false;
  let errors = {};

  if (validator.isEmpty(editProblemModal?.title)) {
    isErr = true;
    errors.title = "Title is required";
  }
  if (validator.isEmpty(editProblemModal?.description)) {
    isErr = true;
    errors.description = "Description is required";
  }

  if (editProblemModal?.steps?.length === 0) {
    isErr = true;
    errors.steps = "Steps is required";
  }

  if (isErr) {
    isErr = false;
    setClientErrors(errors);
  } else {
    isErr = false;
    setClientErrors({});
    dispatch(
      updateProblem({
        data: {
          title: editProblemModal?.title,
          description: editProblemModal?.description,
          steps: editProblemModal?.steps,
          library: editProblemModal?.library,
          language: editProblemModal?.language,
        },
        id: editProblemModal?.id,
        onSuccess: () => {
          dispatch(
            restartProblem({
              id: editProblemModal?.id,
              onSuccess: (data) => {
                dispatch(getAllProblems());
                setEditProblemModal({
                  open: false,
                  id: "",
                  title: "",
                  description: "",
                  library: "",
                  language: "",
                });

                toastSuccess(data);
              },
            })
          );
        },
      })
    );
  }
};

export default handleUpdateProblem;
