import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Config";
import setAuthToken from "../../../utils/axios/setAuthToken";
import { getCookie } from "../../../utils/helper";

export const generatePlantUMLDiagram = createAsyncThunk(
  "Add Log",
  async ({ plantumlCode, userId, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const token = getCookie("token");
      setAuthToken(token);
      const response = await axios.post(
        `${BASE_URL}/api/user/plantUML/generateImage`,
        {
          plantumlCode,
          userId,
        },
        {
          responseType: "blob",
        }
      );
      onSuccess && onSuccess(response?.data);
      return response?.data;
    } catch (error) {
      const errorMessage = error?.response?.data || error?.message;
      onError && onError(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
const initialState = {
  isLoading: false,
  isSuccess: false,
  message: "",
  error: "",
};
const generatePlantUMLDiagramSlice = createSlice({
  name: "Add Log",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generatePlantUMLDiagram.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(generatePlantUMLDiagram.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(generatePlantUMLDiagram.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = action?.payload;
    });
  },
});

export default generatePlantUMLDiagramSlice?.reducer;
