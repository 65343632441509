import { formatDateToStandard } from "../../utils/helper";
import { Button, Space, Tooltip } from "antd";
import styles from "./Home.module.scss";
import handleStepsData from "../../utils/home/handleStepsData";
import handleDeleteProblem from "../../utils/home/handleDeleteProblem";
import { restartProblem } from "../../Redux/Features/Problem/restartProblemSlice";
const DataSource = (
  problemsData,
  getLibraryData,
  setEditProblemModal,
  dispatch,
  navigate,
) => {
  return problemsData?.map((item, i) => ({
    key: i,
    date: (
      <p className={styles.paragraph1}>
        {formatDateToStandard(item?.created_at)}
      </p>
    ),
    title: <p className={styles.paragraph2}>{item?.title}</p>,
    description: (
      <p className={styles.paragraph3}>
        <Tooltip title={item?.description}>{`${item?.description?.slice(
          0,
          50
        )}${item?.description?.length > 78 ? "..." : ""}`}</Tooltip>
      </p>
    ),
    library: (
      <p className={styles.paragraph3}>
        <Tooltip title={handleStepsData(item?.library, getLibraryData)}>
          {handleStepsData(item?.library, getLibraryData)?.slice(0, 30) +
            "  ..."}
        </Tooltip>
      </p>
    ),
    language: <p className={styles.paragraph2}>{item?.language}</p>,

    action: (
      <Space>
        <Button
          style={{ color: "red" }}
          onClick={() => handleDeleteProblem(item?._id, dispatch)}
        >
          Delete
        </Button>

        {/* Edit Problem */}

        <Button
          type="primary"
          size="small"
          onClick={() => {
            setEditProblemModal({
              open: true,
              id: item?._id,
              title: item?.title,
              description: item?.description,
              library: item?.library,
              language: item?.language,
            });
          }}
        >
          Edit
        </Button>

        {/* Start coversation */}

        {Number(item?.stepCount) === 0 && (
          <Button
            type="primary"
            size="small"
            onClick={() => navigate(`/chat/${item?._id}`)}
          >
            Start
          </Button>
        )}

        {/* restart the conversation */}

        {Number(item?.stepCount) > 0 && (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              dispatch(
                restartProblem({
                  id: item?._id,
                  onSuccess: () => {
                    navigate(`/chat/${item?._id}`);
                  },
                })
              );
            }}
          >
            Restart
          </Button>
        )}
        {/* view conversation */}

        {Number(item?.stepCount) > 0 &&
          Number(item?.stepCount) <= Number(item?.maxCount) && (
            <Button
              type="primary"
              size="small"
              onClick={() => navigate(`/chat/${item?._id}`)}
            >
              View
            </Button>
          )}
      </Space>
    ),
  }));
};

export default DataSource;
