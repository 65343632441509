import { toastWarning } from "../../toast";

const handleRejectResponse = (setIsChat,setChats) => {
    toastWarning("Please provide additional input for future guidance");
    setIsChat(true);

    setChats((prev) => {
      const filteredPrev = prev?.map((item) => {
        return { ...item, isMoreButtons: false};
      });
      return [...filteredPrev];
    });
  };


  export default handleRejectResponse;