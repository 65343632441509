import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicLayout from "../../Layout/BasicLayout";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import styles from "./Home.module.scss";
import { getAllProblems } from "../../Redux/Features/Problem/getAllProblemsSlice";
import { getLibrary } from "../../Redux/Features/Promptlet/getLibrarySlice";
import columns from "./Columns";
import DataSource from "./dataSource";
import EditProblemModal from "../../components/Problems/EditProblemModal";
import AddProblemModal from "../../components/Problems/addProblemModal";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [problem, setProblem] = useState({
    title: "",
    description: "",
    library: "",
    language: "",
  });
  const [addProblemModal, setAddProblemModal] = useState(false);
  const [editProblemModal, setEditProblemModal] = useState({
    open: false,
    id: "",
    title: "",
    description: "",
    library: "",
    language: "",
  });

  const { data: promptlets } = useSelector((state) => state?.getLibrary);

  let { data: problemsData, isLoading: getAllProblemsLoading } = useSelector(
    (state) => state?.getAllProblems
  );
  const { isLoading: addProblemLoading } = useSelector(
    (state) => state?.addProblem
  );
  const { isLoading: updateProblemLoading } = useSelector(
    (state) => state?.updateProblem
  );

  let [clientErrors, setClientErrors] = useState({});

  useEffect(() => {
    dispatch(getAllProblems());
    dispatch(getLibrary());
  }, []);

  return (
    <BasicLayout>
      <div className={styles.problems_container}>
        <div className={styles.button_container}>
          <Button type="primary" onClick={() => setAddProblemModal(true)}>
            Add Problem
          </Button>
        </div>
        <div className={styles.table_container}>
          <Table
            loading={getAllProblemsLoading}
            dataSource={DataSource(
              problemsData,
              promptlets,
              setEditProblemModal,
              dispatch,
              navigate
            )}
            columns={columns}
          />
        </div>
      </div>
      <AddProblemModal
        addProblemModal={addProblemModal}
        addProblemLoading={addProblemLoading}
        setProblem={setProblem}
        setAddProblemModal={setAddProblemModal}
        setEditProblemModal={setEditProblemModal}
        clientErrors={clientErrors}
        promptlets={promptlets}
        problem={problem}
        setClientErrors={setClientErrors}
        dispatch={dispatch}
      />

      <EditProblemModal
        editProblemModal={editProblemModal}
        setEditProblemModal={setEditProblemModal}
        updateProblemLoading={updateProblemLoading}
        setClientErrors={setClientErrors}
        dispatch={dispatch}
        clientErrors={clientErrors}
        promptlets={promptlets}
      />
    </BasicLayout>
  );
};

export default Home;
