import { deletePromptlet } from "../../Redux/Features/Promptlet/deletePromptletSlice";
import { deletePromptletData } from "../../Redux/Features/Promptlet/getLibrarySlice";
const { toastSuccess, toastError } = require("../toast");

const handleDeletePromptlet = (id, dispatch) => {
  dispatch(
    deletePromptlet({
      id,
      onSuccess: (data) => {
        dispatch(deletePromptletData(id));
        toastSuccess(data);
      },
      onError: (data) => {
        toastError(data);
      },
    })
  );
};

export default handleDeletePromptlet;
