const addUserChat = (chats,message,setChats) => {
  const newData = {};
  newData.role = "user";
  newData.content = message;
  newData.additionalInformation = message;
  const newChats = [...chats, { ...newData }];
  setChats(newChats);
};

export default  addUserChat;
