import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosErrorHandler, getCookie } from "../../../utils/helper";
import setAuthToken from "../../../utils/axios/setAuthToken";
import axios from "axios";
import { BASE_URL } from "../../../Config";

export const deleteUser = createAsyncThunk(
  "Delete User",
  async ({id,onSuccess}, { rejectWithValue }) => {
    try {
      const token = getCookie("token");
      setAuthToken(token);
      const response = await axios.delete(`${BASE_URL}/api/users/${id}`);
      onSuccess&&onSuccess(response?.data?.message)
      return response?.data;
    } catch (error) {
        axiosErrorHandler(error);
        return rejectWithValue(axios?.response?.data)
    }
  }
);
const initialState = {
    isLoading: false,
    isError: null,
    errors: [],
    isSuccess: false,
    message: "",
  };
const deleteUserSlice = createSlice({
    name:'delete user',
    initialState:initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(deleteUser.pending,(state)=>{
            state.isLoading=true;
            state.isSuccess=false;
        });
        builder.addCase(deleteUser.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isSuccess= action?.payload?.success;
            state.message=action?.payload?.data;
        });
        builder.addCase(deleteUser.rejected,(state,action)=>{
            state.isLoading=false;
            state.isSuccess= action?.payload?.success;
            state.message=action?.payload?.data;
        })
    }

})

export default deleteUserSlice?.reducer;
