import React from "react";
import { Col, Row, Button } from "antd";
import handleDebug from "../utils/problemConversation/Actions/handleDebug";
import handleAcceptResponse from "../utils/problemConversation/Actions/handleAcceptResponse";
import handleEditResponse from "../utils/problemConversation/Actions/handleEdit";
import handleRejectResponse from "../utils/problemConversation/Actions/handleRejectResponse";
import handleRequeryResponse from "../utils/problemConversation/Actions/handleRequeryResponse";
import { getCookie } from "../utils/helper";

const ActionButtons = ({
  item,
  acceptResponseLoading,
  isMdOrAbove,
  conversationId,
  setOpenDebugModal,
  editedResponse,
  setEditedResponse,
  setIsEditorModalVisible,
  chats,
  setIsChat,
  setChats,
  setIsTyping,
  dispatch,
  navigate,
  setMessage,
  problemData,
  problemId,
  message
}) => {
  const user = JSON.parse(getCookie("authUser") || "{}");
  return (
    <Row justify="space-between" align="middle" gutter={[8, 8]}>
      {!item?.isResume && item?.isMoreButtons && (
        <>
          <Col>
            <Button
              type="dashed"
              onClick={() =>
                handleAcceptResponse(
                  {
                    ...item?.data,
                  },
                  chats,
                  dispatch,
                  setChats,
                  setIsChat,
                  setMessage,
                  setIsTyping,
                  navigate,
                  problemData,
                  problemId,
                  message,
                  conversationId
                )
              }
              disabled={acceptResponseLoading}
              loading={acceptResponseLoading}
            >
              Accept
            </Button>
          </Col>

          <Col>
            <Button
              type="dashed"
              disabled={!item?.humanChecker}
              onClick={() => handleRejectResponse(setIsChat, setChats)}
            >
              Reject
            </Button>
          </Col>

          {!item?.data?.reject && (
            <Col>
              <Button
                type="dashed"
                onClick={() =>
                  handleRequeryResponse(
                    chats,
                    setChats,
                    setIsTyping,
                    dispatch,
                    navigate,
                    problemId,
                    problemData,
                    conversationId
                  )
                }
              >
                Requery
              </Button>
            </Col>
          )}
          <Col>
            <Button
              type="dashed"
              onClick={() =>
                handleEditResponse(
                  { ...item?.data },
                  editedResponse,
                  setEditedResponse,
                  setIsEditorModalVisible,
                  chats,
                  conversationId,
                  dispatch
                )
              }
            >
              Edit
            </Button>
          </Col>
        </>
      )}
      {(user?.debugLevel !== "none" || user?.userType ==="admin")&& (
        <Col>
          <Button
            type="dashed"
            className="debug-hidden"
            style={{
              color: "red",
              marginLeft: "auto",
              display: isMdOrAbove ? "none" : "block",
              fontSize: "14px",
            }}
            onClick={() =>
              handleDebug(
                item?.data?.library?.stepId,
                conversationId,
                setOpenDebugModal,
                dispatch
              )
            }
          >
            Debug
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default ActionButtons;
