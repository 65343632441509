import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Config";
import setAuthToken from "../../../utils/axios/setAuthToken";
import { getCookie } from "../../../utils/helper";

export const compileChatsToDocument = createAsyncThunk(
  "Add Log",
  async ({ compileChats, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const token = getCookie("token");
      setAuthToken(token);
      const response = await axios.post(
        `${BASE_URL}/api/user/document/compileToDocx`,
        { compileChats },
        {
          responseType: "blob",
        }
      );
      onSuccess && onSuccess(response?.data);
      return response?.data;
    } catch (error) {
      const errorMessage = error?.response?.data || error?.message;
      onError && onError(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
const initialState = {
  isLoading: false,
  isSuccess: false,
  message: "",
};
const compileChatsToDocumentSlice = createSlice({
  name: "Add Log",
  initialState: initialState,
  reducers: {
    cancelCompileToDocument: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(compileChatsToDocument.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(compileChatsToDocument.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action?.payload?.data;
    });
    builder.addCase(compileChatsToDocument.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.message = action?.payload;
    });
  },
});
export const {cancelCompileToDocument} = compileChatsToDocumentSlice.actions;
export default compileChatsToDocumentSlice?.reducer;
