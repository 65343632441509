import { acceptResponse } from "../../../Redux/Features/Chat/acceptResponseSlice";
import { addLog } from "../../../Redux/Features/Log/addLogSlice";
import { toastSuccess } from "../../toast";
import handleMessage from "../Chat/handleMessage";

const handleAcceptResponse = async (
  data,
  chats,
  dispatch,
  setChats,
  setIsChat,
  setMessage,
  setIsTyping,
  navigate,
  problemData,
  problemId,
  message,
  conversationId
) => {
  const { _id, stepId } = chats[chats.length - 1]?.data?.library;
  const input = data;
  const startTime = Date.now();
  dispatch(
    acceptResponse({
      id: problemId,
      data: { response: data },
      onSuccess: (data) => {
        setChats((prev) => {
          const filteredPrev = prev?.map((item, i) => {
            return { ...item, isMoreButtons: false };
          });
          return [...filteredPrev];
        });
        handleMessage(
          chats[chats?.length - 2]?.content,
          setChats,
          chats,
          setMessage,
          setIsChat,
          dispatch,
          setIsTyping,
          navigate,
          problemData,
          problemId,
          message,
          conversationId
        );
        toastSuccess(data);
      },
    })
  );

  const endTime = Date.now();

  dispatch(
    addLog({
      conversationId,
      stepId,
      libraryId: _id,
      debugLevel: 0,
      action: "accept",
      duration: endTime - startTime,
      input,
      output: chats[chats.length - 1]?.data,
      error: "",
    })
  );
};

export default handleAcceptResponse;
