import { toastSuccess } from "../../toast";
import handleMessage from "../Chat/handleMessage";

const handleResume = (
  setChats,
  chats,
  setMessage,
  setIsChat,
  dispatch,
  setIsTyping,
  navigate,
  problemData,
  problemId,
  message,
  conversationId,
  setIsResume
) => {
  setIsResume(false);
  handleMessage(
    chats[chats?.length - 2]?.content,
    setChats,
    chats,
    setMessage,
    setIsChat,
    dispatch,
    setIsTyping,
    navigate,
    problemData,
    problemId,
    message,
    conversationId
  );
  toastSuccess("Resumed")
};

export default handleResume;
