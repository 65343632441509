const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "1",
      // width: 150,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "2",
      // width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "3",
      // width: 400,
    },
    {
      title: "Promptlet",
      dataIndex: "library",
      key: "4",
      // width: 500,
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "5",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "5",
    },
  ];

  export default columns;